import React from "react";
import { DesktopNavbar, MobileNavbar } from "./navbar";
import { DesktopFooter, MobileFooter } from "./footer";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="relative w-full font-cairo bg-primary/10">
      <DesktopNavbar />
      <MobileNavbar />
      <div className="min-h-screen">{children}</div>
      <DesktopFooter />
      <MobileFooter />
    </div>
  );
};

export default Layout;
