import clsx from "clsx";
import React, { useState } from "react";

const TextField: React.FC<
  { label: string } & React.InputHTMLAttributes<HTMLInputElement>
> = ({ label, className, ...props }) => {
  const [error] = useState("");

  return (
    <div className="w-full">
      <label
        htmlFor={label + "-label-id"}
        className="block text-sm sm:text-base font-semibold leading-[19px] text-secondary"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          name={label + "-label-id"}
          id={label + "-label-id"}
          className={clsx(
            "block w-full rounded-md bg-secondary/5 border-0 py-2 px-3 ring-secondary/30 focus:ring-secondary ring-1 ring-inset outline-none text-secondary font-semibold sm:text-sm sm:leading-6 placeholder:text-secondary placeholder:text-sm placeholder:leading-[19px]",
            {
              "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500":
                !!error,
            },
            className
          )}
          {...props}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
};

export default TextField;
