export const CALL_US_CONTACT = "9968573952";
export const ADDRESS_DETAILS =
  "402, AECS, 2nd A Cross, Bengaluru, Karnataka 560026";

export const SUPPORT_EMAIL_ADDRESS = "team@z2alaap.com";

export const OFFICE_LATITUDE = "13.00620045209822";
export const OFFICE_LONGITUDE = "77.65128903897177";

export const OurServicesDetails = [
  {
    title: "Full Truck Load",
    desc: `Optimize large shipments with our full truckload services. Dedicated trucks ensure your cargo moves directly to its destination. Enjoy cost-effective and timely transportation for bulk goods.`,
  },
  {
    title: "Part Truck Load",
    desc: `Benefit from flexible shipping with our part truckload services. Share space with other shipments for cost savings. Perfect for medium-sized cargo that doesn't require a full truck.`,
  },
  {
    title: "Fulfillment",
    desc: `Enhance your order processing with our fulfilment solutions. From picking and packing to shipping, we manage it all. Focus on growing your business while we handle your logistics.`,
  },
  {
    title: "Warehouse",
    desc: `Secure and efficient warehousing solutions tailored to your needs. Our facilities offer state-of-the-art storage, inventory management, and distribution services. Optimize your supply chain with our comprehensive warehouse solutions.`,
  },
  {
    title: "Freight Forwarding",
    desc: `Efficiently manage your global shipments with our comprehensive freight forwarding services. We handle documentation, customs clearance, and logistics. Trust us to streamline your supply chain from origin to destination.`,
  },
  {
    title: "Last Mile Logistics",
    desc: `Ensure timely and accurate deliveries to your customers with our last-mile logistics solutions. We prioritize speed, efficiency, and customer satisfaction. Experience seamless final delivery with our advanced tracking.`,
  },
];

export const suitOfSolutionsData = [
  {
    img: "/homepage/india-map.png",
    title: "D2C Brands",
    desc: `We provide an integrated logistics solution built on Express Parcel, Cross Border, Warehousing, Freight and Software value added services that helps brands deliver faster and provide a superior experience`,
  },
  {
    img: "/homepage/person.png",
    title: "Personal Courier",
    desc: `India’s only online courier solution for all your personal shipping needs. Send from your location to anywhere in the country and internationally with free doorstep pickup and real time tracking through our app`,
  },
  {
    img: "/homepage/building.png",
    title: "B2B Enterprises",
    desc: `We provide customised solutions to serve your factory to retailer supply chain end-to-end using our integrated warehousing, technology capabilities and logistics network that improve reliability and reduce cost`,
  },
];

export const linkedInLink = "https://www.linkedin.com/in/z2a-laap-33a902307/";

export const ourValuesData = [
  "Customer Focus",
  "Integrity",
  "Innovation",
  "Sustainability",
  "Collaboration",
  "Excellence",
];
