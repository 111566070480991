import clsx from "clsx";
import React from "react";
import Spinner from "../../icons/spinner";

type ButtonProps = {
  isLoading?: boolean;
  variant?: "primary" | "secondary" | "danger" | "warning";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  children,
  className,
  isLoading = false,
  variant = "primary",
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      className={clsx(
        "flex items-center font-bold justify-center px-2 py-[10px] border transition-colors duration-300 border-secondary disabled:opacity-50",
        {
          "text-white bg-gray-900 border-gray-900 hover:bg-nav hover:text-secondary":
            variant === "primary",
          "text-gray-900 bg-white border-gray-900 hover:bg-gray-900 hover:text-white":
            variant === "secondary",
          "text-danger bg-danger-light border-0 hover:shadow-sm":
            variant === "danger",
          "text-warning bg-yellow-100 border-0 hover:shadow-sm":
            variant === "warning",
        },
        className
      )}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};

export default Button;
