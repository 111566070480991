import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { Fragment, useContext, useState } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "../common/button";
import { LayoutContext } from "../../context/layout-context";
import Careers from "../layout/people-and-culture";
import ReachUs from "../layout/reach-us";

type SidebarProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
};

const MobileSidebar: React.FC<SidebarProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { toggleEnquiryDialog } = useContext(LayoutContext);
  const [openCareerPopup, setCareerPopup] = useState(false);
  const [openReachUsPopup, setReachUsPopup] = useState(false);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div className="fixed inset-0 flex">
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5 outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <MdClose className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              <div className="relative flex flex-col items-center gap-y-8 overflow-y-auto bg-nav px-4 py-4 grow pb-2">
                <div className="w-32">
                  <img
                    src={"/logo.svg"}
                    alt="logo"
                    className=" w-full h-auto mx-auto"
                  />
                </div>

                <ul className="list-none text-lg font-bold space-y-5 flex flex-col items-center text-secondary ">
                  <li className="cursor-pointer" onClick={() => navigate("/")}>
                    Home
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => navigate("/company")}
                  >
                    Company
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => setReachUsPopup(true)}
                  >
                    Reach Us
                  </li>
                  <li
                    className="cursor-pointer"
                    onClick={() => setCareerPopup(true)}
                  >
                    People & Culture
                  </li>
                </ul>
                <Button
                  onClick={() => {
                    toggleEnquiryDialog(true);
                    setOpen(false);
                  }}
                  className="absolute bottom-3 w-4/5 font-semibold md:text-xs lg:text-base text-right text-secondary drop-shadow-sm"
                >
                  Enquire
                </Button>
              </div>
              <Careers isOpen={openCareerPopup} setIsOpen={setCareerPopup} />
              <ReachUs isOpen={openReachUsPopup} setIsOpen={setReachUsPopup} />
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MobileSidebar;
