import axios, { AxiosResponse } from "axios";
const AppURL = process.env.REACT_APP_BACKEND;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = AppURL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "true";

const axiosInstance = axios.create();

function handleSuccess<T>(
  response: AxiosResponse<T>
): Promise<AxiosResponse<T>> {
  return Promise.resolve(response);
}

function handleError(error: any) {
  if (error.message === "Network Error") {
    return Promise.reject(error);
  }
  return Promise.reject(error);
}

axiosInstance.interceptors.response.use(handleSuccess, handleError);

export { axiosInstance };
