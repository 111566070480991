const Company = () => {
  return (
    <div className="grid grid-cols-2 gap-7 sm:w-4/5 px-4 sm:px-0 sm:mx-auto py-5 sm:py-10 text-secondary pb-48">
      <div className="col-span-2 sm:col-span-1 flex flex-col gap-y-3 order-2 sm:order-1">
        <h2 className="hidden sm:block text-5xl sm:text-5xl font-semibold mb-4 sm:mb-6">
          About Company
        </h2>
        <h3 className="font-semibold text-4xl">Z2A LAAP</h3>
        <p className="italic -mt-1">
          Making Eco-friendly quick deliveries with economical viability
        </p>
        <ul className="list-inside font-medium flex flex-col gap-y-3 sm:gap-y-[23px] text-lg sm:text-xl">
          <li className="">
            Z2A Logistics as a Platform helps groups with their logistics and
            warehouse requirements on a economical and optimal level
          </li>
          <li>
            A Complete Solution of Local, Intercity and National Deliveries with
            technology and VAS for Customer Delight
          </li>
          <li>
            Presence in top six metros with India's most critical zone of J&K
            and North East
          </li>
          <li>
            Bring to the table win-win survival strategies to ensure proactive
            domination
          </li>
          <li>
            Asset light model with AI & ML based approach to automate logistics
            network with 15 years of learnings and expertise
          </li>
        </ul>
      </div>
      <div className="col-span-2 sm:col-span-1 order-1 sm:order-2">
        <img
          src="/company/logistics.jpeg"
          alt="company-page-img"
          className="h-[50vh] sm:h-[60vh] drop-shadow-md"
        />
      </div>
    </div>
  );
};

export default Company;
