import Hero from "../../components/home/hero";
import Layout from "../../components/layout";
import SuitOfSolutions from "../../components/home/suit-of-solutions";
import OurProducts from "../../components/home/our-products";
import WhyChooseUs from "../../components/home/why-choose-us";
import OurValues from "../../components/home/our-values";

const HomePage = () => {
  return (
    <Layout>
      <div className="relative flex flex-col gap-y-7 pb-44">
        <Hero />
        <OurProducts />
        <SuitOfSolutions />
        <WhyChooseUs />
        <OurValues />
      </div>
    </Layout>
  );
};

export default HomePage;
