import { PiUserFocusBold } from "react-icons/pi";
import { RiHonorOfKingsFill } from "react-icons/ri";
import { BsLifePreserver } from "react-icons/bs";
import { BsBrilliance } from "react-icons/bs";

const ValueBox = ({ val }: { val: { label: string; Icon: any } }) => {
  return (
    <div className="flex flex-col items-center border border-secondary py-4 cursor-pointer rounded-sm bg-nav hover:scale-y-105 hover:scale-x-[1.02] transition-all duration-500 drop-shadow-md">
      {val.Icon}
      <h3 className="text-base leading-[31px] md:text-xl md:leading-[44px] font-bold">
        {val.label}
      </h3>
    </div>
  );
};

const OurValues = () => {
  const ourValuesData = [
    { label: "Customer Focus", Icon: <PiUserFocusBold size={30} /> },
    { label: "Integrity", Icon: <RiHonorOfKingsFill size={30} /> },
    {
      label: "Innovation",
      Icon: <img src="/creative.png" height="30px" width="30px" />,
    },
    {
      label: "Collaboration",
      Icon: (
        <img src="collaboration.png" alt="team" height="32px" width="32px" />
      ),
    },
    { label: "Sustainability", Icon: <BsLifePreserver size={30} /> },
    { label: "Excellence", Icon: <BsBrilliance size={30} /> },
  ];

  return (
    <div className="flex flex-col font-bold space-y-4 md:space-y-8 w-[95%] sm:w-[75%] mx-auto text-secondary">
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-secondary" />
        </div>
        <div className="relative flex justify-center">
          <span className="px-2 text-2xl sm:text-3xl md:text-4xl font-semibold sm:font-bold leading-6 z-10 bg-[#f3fbee]">
            Our Values
          </span>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 gap-3 sm:gap-3 md:gap-x-4 sm:gap-y-5 md:grid-cols-3">
        {ourValuesData?.map((val: any, index: number) => {
          return <ValueBox key={index} val={val} />;
        })}
      </div>
    </div>
  );
};

export default OurValues;
