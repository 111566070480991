const Hero = () => {
  const heroImages = [
    "/homepage/hero1.jpeg",
    "/homepage/hero2.jpeg",
    "/homepage/hero3.jpeg",
    "/homepage/hero4.jpeg",
  ];

  const imageIdx = Math.floor(Math.random() * 4);

  return (
    <div className="relative">
      <img
        src={heroImages[imageIdx]}
        alt="hero"
        className="h-[40vh] w-auto object-cover xsmall:h-[60vh] xsmall:w-full xsmall:object-center"
      />
      <div className="absolute xsmall:flex text-secondary hidden flex-col top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 gap-y-2 sm:gap-y-4 text-center">
        <h3 className="text-3xl sm:text-4xl text-shadow-secondary-outline md:text-5xl lg:text-6xl drop-shadow-2xl">
          Making Deliveries Simple
        </h3>
      </div>
    </div>
  );
};

export default Hero;
