import { suitOfSolutionsData } from "../../data";

const SuitOfSolutions = () => {
  return (
    <div className="w-full bg-gray-900 text-white min-h-[200px] py-5 md:py-8 px-6 md:px-24 flex flex-col gap-y-10 md:gap-y-14">
      <h2 className="font-bold text-2xl md:text-4xl text-center md:text-left">
        <span className="">Z2A Laap's</span> suite of solutions for
      </h2>
      <div className="flex flex-col sm:flex-row gap-y-11 sm:gap-x-5 sm:justify-between">
        {suitOfSolutionsData.map((d, i) => (
          <SuitOfSolutionsCard key={i} {...d} />
        ))}
      </div>
    </div>
  );
};

const SuitOfSolutionsCard: React.FC<{
  title: string;
  img: string;
  desc: string;
}> = ({ title, desc, img }) => {
  return (
    <div className="flex flex-col items-center sm:items-start gap-y-3 text-center sm:text-left">
      <img src={img} alt="suitImage" />
      <h3 className="text-xl sm:text-2xl font-semibold">{title}</h3>
      <p className="text-lg sm:max-w-[80%]">{desc}</p>
    </div>
  );
};

export default SuitOfSolutions;
