import clsx from "clsx";
import React from "react";

const TextAreaField: React.FC<
  { label: string } & React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({ label, className, ...props }) => {
  return (
    <div className="w-full">
      <label
        htmlFor={label + "-label-id"}
        className="block text-sm sm:text-base font-semibold leading-[19px] text-secondary"
      >
        {label}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <textarea
          name={label + "-label-id"}
          id={label + "-label-id"}
          rows={5}
          className={clsx(
            "block w-full rounded-md border-0 py-2 px-3 bg-secondary/5 ring-secondary/30 focus:ring-secondary text-secondary font-semibold ring-1 ring-inset outline-none sm:text-sm sm:leading-6 bg- placeholder:text-secondary/60 placeholder:text-sm placeholder:leading-[19px]",
            className
          )}
          {...props}
        />
      </div>
    </div>
  );
};

export default TextAreaField;
