import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { NavLink, useNavigate } from "react-router-dom";
import { MdCall } from "react-icons/md";
import { MdLocationPin } from "react-icons/md";
import { MdMail } from "react-icons/md";
import Button from "../common/button";
import {
  ADDRESS_DETAILS,
  CALL_US_CONTACT,
  OFFICE_LATITUDE,
  OFFICE_LONGITUDE,
  SUPPORT_EMAIL_ADDRESS,
  linkedInLink,
} from "../../data";
import { useContext, useState } from "react";
import { LayoutContext } from "../../context/layout-context";
import Careers from "./people-and-culture";
import ReachUs from "./reach-us";

export const DesktopFooter = () => {
  const { toggleEnquiryDialog } = useContext(LayoutContext);
  const [openCareerPopup, setCareerPopup] = useState(false);
  const [openReachUsPopup, setReachUsPopup] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="w-full bg-nav hidden md:block animate-slide-up">
      <div className="w-[95%] min-h-[300px] mx-auto flex gap-x-3 justify-between pt-12 pb-6 text-secondary">
        <div className="flex flex-col gap-y-3">
          <img
            src="/logo.svg"
            alt="logo"
            className="h-[130px] w-auto mx-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
          <ul className="list-none flex gap-x-2 items-center justify-between">
            <SocialMediaLink icon={<RiInstagramFill size={27} />} />
            <SocialMediaLink icon={<FaFacebook size={25} />} />
            <SocialMediaLink
              icon={<FaLinkedin size={25} />}
              src={linkedInLink}
            />
            <SocialMediaLink icon={<FaXTwitter size={25} />} />
          </ul>
        </div>

        <ul className="list-none flex flex-col gap-y-4 mt-4">
          <h4 className="text-xl font-bold">Company</h4>
          <NavLink to="/">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Home
            </p>
          </NavLink>
          <NavLink to="/company">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              About Us
            </p>
          </NavLink>
          <p
            className="cursor-pointer text-base md:text-lg font-medium"
            onClick={() => setCareerPopup(true)}
          >
            People & Culture
          </p>
          <p
            className="cursor-pointer text-base md:text-lg font-medium"
            onClick={() => setReachUsPopup(true)}
          >
            Reach Us
          </p>
        </ul>

        <ul className="list-none flex flex-col gap-y-3 mt-4">
          <h4 className="text-xl font-bold">Services</h4>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Warehouse
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Fulfillment
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Full Truck Load
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Part Truck Load
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Freight Forwarding
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Last Mile Logistics
            </p>
          </NavLink>
        </ul>

        <div className="flex flex-col space-y-3 md:space-y-5 mt-4">
          <h4 className="text-xl font-bold">Contact Us</h4>
          <div className="flex space-x-3 items-center text-base font-medium">
            <MdCall
              className="cursor-pointer"
              size={18}
              onClick={() =>
                window.open(
                  `tel:+91${CALL_US_CONTACT.split("-").join("")}`,
                  "_blank"
                )
              }
            />
            <p>+91 {CALL_US_CONTACT}</p>
          </div>
          <div className="flex gap-x-3 items-start text-base font-medium">
            <MdLocationPin
              size={20}
              className="cursor-pointer"
              onClick={() => {
                const url = `https://www.google.com/maps?q=${OFFICE_LATITUDE},${OFFICE_LONGITUDE}`;
                window.open(url, "_blank");
              }}
            />
            <p className="max-w-[305px] text-base">{ADDRESS_DETAILS}</p>
          </div>
          <div className="flex space-x-3 text-base font-medium items-center">
            <MdMail
              size={18}
              className="cursor-pointer"
              onClick={() =>
                window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}`, "_blank")
              }
            />
            <p className="">{SUPPORT_EMAIL_ADDRESS}</p>
          </div>
        </div>

        <div className="flex flex-col gap-y-3 mt-4">
          <h4 className="text-xl font-semibold">Have Some Questions?</h4>
          <p className="">let us connect with you!</p>
          <Button
            className="drop-shadow-md rounded-sm hover:bg-nav"
            onClick={() => toggleEnquiryDialog(true)}
          >
            Enquiry
          </Button>
        </div>
      </div>
      <Careers isOpen={openCareerPopup} setIsOpen={setCareerPopup} />
      <ReachUs isOpen={openReachUsPopup} setIsOpen={setReachUsPopup} />
    </div>
  );
};

export const MobileFooter = () => {
  const { toggleEnquiryDialog } = useContext(LayoutContext);
  const [openCareerPopup, setCareerPopup] = useState(false);
  const [openReachUsPopup, setReachUsPopup] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="w-full bg-nav md:hidden animate-slide-up">
      <div className="min-h-[300px] grid grid-cols-2 gap-x-3 gap-y-10 pt-12 pb-6 px-4 text-secondary">
        <div className="col-span-2 mx-auto flex flex-col gap-y-3">
          <img
            src="/logo.svg"
            alt="logo"
            className="h-[130px] w-auto mx-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
          <ul className="list-none flex gap-x-4 items-center justify-between">
            <SocialMediaLink icon={<RiInstagramFill size={27} />} />
            <SocialMediaLink icon={<FaFacebook size={25} />} />
            <SocialMediaLink
              icon={<FaLinkedin size={25} />}
              src={linkedInLink}
            />
            <SocialMediaLink icon={<FaXTwitter size={25} />} />
          </ul>
        </div>

        <ul className="col-span-1 list-none flex flex-col gap-y-2 pl-5 xsmall:pl-10">
          <h4 className="text-xl font-bold">Company</h4>
          <NavLink to="/">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Home
            </p>
          </NavLink>
          <NavLink to="/company">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              About Us
            </p>
          </NavLink>
          <p
            className="cursor-pointer text-base md:text-lg font-medium"
            onClick={() => setReachUsPopup(true)}
          >
            Reach Us
          </p>
          <p
            className="cursor-pointer text-base md:text-lg font-medium"
            onClick={() => setCareerPopup(true)}
          >
            People & Culture
          </p>
        </ul>

        <div className="col-span-1 flex flex-col space-y-3 md:space-y-5">
          <h4 className="text-xl font-bold">Contact Us</h4>
          <div className="flex space-x-3 items-center text-base font-medium">
            <MdCall
              className="cursor-pointer"
              size={18}
              onClick={() =>
                window.open(
                  `tel:+91${CALL_US_CONTACT.split("-").join("")}`,
                  "_blank"
                )
              }
            />
            <p>+91 {CALL_US_CONTACT}</p>
          </div>
          <div className="flex gap-x-3 items-start text-base font-medium">
            <MdLocationPin
              size={40}
              className="cursor-pointer"
              onClick={() => {
                const url = `https://www.google.com/maps?q=${OFFICE_LATITUDE},${OFFICE_LONGITUDE}`;
                window.open(url, "_blank");
              }}
            />
            <p className="max-w-[305px] text-base">{ADDRESS_DETAILS}</p>
          </div>
          <div className="flex space-x-3 text-base font-medium items-center">
            <MdMail
              size={18}
              className="cursor-pointer"
              onClick={() =>
                window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}`, "_blank")
              }
            />
            <p className="">{SUPPORT_EMAIL_ADDRESS}</p>
          </div>
        </div>

        <ul className="col-span-1 list-none flex flex-col gap-y-3 pl-5 xsmall:pl-10">
          <h4 className="text-xl font-bold">Services</h4>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Warehouse
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Fulfillment
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Full Truck Load
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Part Truck Load
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Freight Forwarding
            </p>
          </NavLink>
          <NavLink to="/#">
            <p className="cursor-pointer text-base md:text-lg font-medium">
              Last Mile Logistics
            </p>
          </NavLink>
        </ul>

        <div className="col-span-1 flex flex-col gap-y-3">
          <h4 className="text-xl font-semibold">Have Some Questions?</h4>
          <p className="">let us connect with you!</p>
          <Button
            className="drop-shadow-md rounded-sm hover:bg-nav"
            onClick={() => toggleEnquiryDialog(true)}
          >
            Enquiry
          </Button>
        </div>
      </div>
      <Careers isOpen={openCareerPopup} setIsOpen={setCareerPopup} />
      <ReachUs isOpen={openReachUsPopup} setIsOpen={setReachUsPopup} />
    </div>
  );
};

const SocialMediaLink: React.FC<{ icon: any; src?: string }> = ({
  icon,
  src,
}) => {
  return (
    <li
      className="cursor-pointer"
      onClick={() => !!src && window.open(src, "_blank")}
    >
      {icon}
    </li>
  );
};
