import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "sonner";

import reportWebVitals from "./reportWebVitals";
import "./index.css";
import App from "./routes";
import { LayoutContextProvider } from "./context/layout-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <LayoutContextProvider>
      <App />
      <Toaster position="top-center" />
    </LayoutContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
