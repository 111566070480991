import { createContext, useState } from "react";
import Popup from "../components/common/popup";
import EnquiryForm from "../components/enquiry";

interface LayoutContextProps {
  enableEnquiryDialog: boolean;
  toggleEnquiryDialog: (arg: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
  enableEnquiryDialog: false,
  toggleEnquiryDialog: () => {},
});

export const LayoutContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [enableEnquiryDialog, toggleEnquiryDialog] = useState(false);

  return (
    <LayoutContext.Provider
      value={{
        enableEnquiryDialog,
        toggleEnquiryDialog,
      }}
    >
      {children}
      <Popup
        open={enableEnquiryDialog}
        setOpen={toggleEnquiryDialog}
        title="Enquiry"
      >
        <div className="mt-5">
          <EnquiryForm />
        </div>
      </Popup>
    </LayoutContext.Provider>
  );
};
