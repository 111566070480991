import React from "react";
import { OurServicesDetails } from "../../data";

const OurProducts = () => {
  return (
    <div className="flex flex-col mt-9 sm:w-4/5 sm:mx-auto text-secondary">
      <h1 className="text-4xl sm:text-5xl font-semibold text-center drop-shadow-md">
        Our Services
      </h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-y-5 sm:gap-y-8 gap-x-5 sm:gap-x-8 px-5 sm:px-10 py-7">
        {OurServicesDetails.map((d, i) => (
          <Card key={i} {...d} />
        ))}
      </div>
    </div>
  );
};

const Card: React.FC<{ title: string; desc: string }> = ({ title, desc }) => {
  return (
    <div className="drop-shadow-lg flex bg-[#eddff7] hover:bg-[#dfc2f0] flex-col space-y-3 sm:space-y-5 rounded-[4px] px-4 py-7 col-span-1 transition-all duration-500 cursor-pointer hover:scale-[103%] hover:rounded-lg">
      <h3 className="text-xl sm:text-2xl font-bold">{title}</h3>
      <p className="text-base sm:text-[17px]">{desc}</p>
    </div>
  );
};

export default OurProducts;
