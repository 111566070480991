import Layout from "../../components/layout";
import Company from "../../components/company";
import { useEffect } from "react";

const CompanyPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Layout>
      <Company />
    </Layout>
  );
};

export default CompanyPage;
