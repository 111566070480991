import { MdEmail } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import { MdOutlineDiversity3 } from "react-icons/md";
import { GiLifeInTheBalance } from "react-icons/gi";

import Popup from "../common/popup";
import { SUPPORT_EMAIL_ADDRESS } from "../../data";
import { IconType } from "react-icons";

const PeopleAndCulture: React.FC<{
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  return (
    <div className="">
      <Popup open={isOpen} setOpen={setIsOpen} title="People & Culture">
        {/* <div className="flex flex-col gap-y-3 mt-4 text-secondary">
          <div className="flex justify-between items-center">
            <p className="">Life at Z2A</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="">Diversity & Inclusion</p>
          </div>
          <div className="flex justify-between items-center">
            <p className="">Work with Us</p>
            <GoArrowRight
              size={26}
              className="cursor-pointer"
              onClick={() =>
                window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}`, "_blank")
              }
            />
          </div>
        </div> */}
        <div className="flex flex-col gap-y-4 mt-4 text-secondary">
          <LineItem label="Life at Z2A" Icon={GiLifeInTheBalance} />
          <LineItem label="Diversity & Inclusion" Icon={MdOutlineDiversity3} />
          <LineItem
            label="Work with Us"
            onClick={() =>
              window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}`, "_blank")
            }
            Icon={MdEmail}
          />
        </div>
      </Popup>
    </div>
  );
};

const LineItem: React.FC<{
  label: string;
  onClick?: (arg: any) => void;
  Icon: IconType;
}> = ({ label, onClick, Icon }) => {
  return (
    <div className="flex justify-between items-center text-secondary">
      <div className="flex gap-x-3 items-center">
        <Icon size={22} />
        <p className="">{label}</p>
      </div>
      {onClick && (
        <GoArrowRight size={26} className="cursor-pointer" onClick={onClick} />
      )}
    </div>
  );
};

export default PeopleAndCulture;
