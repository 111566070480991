import { useContext, useState } from "react";
import TextField from "../common/inputs/text-field";
import { LayoutContext } from "../../context/layout-context";
import TextAreaField from "../common/inputs/text-area";
import { axiosInstance } from "../../lib/utils/helper";
import { toast } from "sonner";

const EnquiryForm = () => {
  const [formState, setFormState] = useState<Record<string, any>>({});
  const { toggleEnquiryDialog } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);

  const handleChange = (fieldName: string, val: any) => {
    setFormState((prev) => ({
      ...prev,
      [fieldName]: val,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);

    const formToSubmit = { ...formState };
    formToSubmit.phone = Number(formToSubmit.phone);

    axiosInstance
      .post("/", formToSubmit)
      .then(() => {
        toggleEnquiryDialog(false);
        toast.success(
          "We have received your response. Our Team will connect with you shortly!"
        );
      })
      .catch(() =>
        toast.error("We have encountered some error processing your request.")
      )
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-y-3">
      <TextField
        label="Name"
        value={formState.name ?? ""}
        onChange={(e) => handleChange("name", e.target.value)}
        type="text"
        required
      />
      <TextField
        label="Phone"
        value={formState.phone ?? ""}
        onChange={(e) => handleChange("phone", e.target.value)}
        type="number"
        required
      />
      <TextField
        label="Email"
        value={formState.email ?? ""}
        onChange={(e) => handleChange("email", e.target.value)}
        type="email"
        required
      />
      <TextAreaField
        label="Requirement"
        value={formState.requirement ?? ""}
        onChange={(e) => handleChange("requirement", e.target.value)}
        placeholder="Enter your doubts here"
        required
      />
      <button
        type="submit"
        disabled={loading}
        className="col-span-2 ml-auto mt-3 px-3 disabled:bg-slate-300 py-3 text-[15px] text-white disabled:text-gray-900 bg-gray-900 leading-[18px] text-center rounded-[4px] outline-none"
      >
        Get In Touch
      </button>
    </form>
  );
};

export default EnquiryForm;
