const WhyChooseUs = () => {
  return (
    // <div className="flex flex-col-reverse sm:flex-row sm:justify-evenly gap-y-5 sm:gap-x-8 text-secondary">
    //   <div className="flex flex-col gap-y-1 sm:gap-y-5 text-center sm:text-left items-center justify-center">
    //     <h3 className="text-3xl sm:text-4xl font-semibold">
    //       Trusted Supply Chain Partner for all supply chain needs.
    //     </h3>
    //     <p className="text-lg font-medium">
    //       Trusted by more than <b>1 lakh</b> large and small sellers
    //     </p>
    //   </div>
    //   <div className="">
    //     <img src="/homepage/tracking.png" alt="tracking" />
    //   </div>
    // </div>
    <div className="grid grid-cols-2 gap-4 px-10  md:w-4/5 mx-auto py-10">
      <div className="col-span-2 sm:col-span-1">
        <img
          src="/homepage/section2.1.jpeg"
          className="w-full h-[400px] object-cover object-top"
          alt="section2"
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <img
          src="/homepage/section2.2.jpeg"
          className="w-full h-[400px] object-cover object-bottom"
          alt="section2"
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <img
          src="/homepage/section2.3.jpeg"
          className="w-full h-[400px]"
          alt="section2"
        />
      </div>
      <div className="col-span-2 sm:col-span-1">
        <img
          src="/homepage/section2.4.jpeg"
          className="w-full h-[400px] object-cover object-center"
          alt="section2"
        />
      </div>
    </div>
  );
};

export default WhyChooseUs;
