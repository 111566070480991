import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { Fragment, useState } from "react";
import { ourValuesData } from "../../data";

const Values = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="">
      <Popover className="relative">
        {() => (
          <>
            <PopoverButton
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`
                  text-secondary ${isOpen ? "" : "text-opacity-90"}
                  group inline-flex items-center font-semibold text-base md:text-[17px] outline-none`}
            >
              <span>Our Values</span>
            </PopoverButton>
            <Transition
              as={Fragment}
              show={isOpen}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel
                className="absolute z-50 mt-4 w-[180px] -right-[110%] transform px-4 sm:px-0"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div className="rounded-lg shadow-lg ring-1 ring-black/5 bg-white">
                  <div className="grid grid-cols-1 gap-x-3 gap-y-3 p-4 bg-primary/10 text-secondary">
                    {ourValuesData.map((pd) => (
                      <div
                        className="col-span-1 cursor-pointer hover:bg-primary/10"
                        key={pd}
                      >
                        <p className="font-medium">{pd}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </PopoverPanel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Values;
