import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import HomePage from "./home";
import PageNotFound from "./page-not-found";
import CompanyPage from "./company";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/company" element={<CompanyPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
