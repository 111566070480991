import { MdEmail } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import { MdLocalPhone } from "react-icons/md";

import Popup from "../common/popup";
import { CALL_US_CONTACT, SUPPORT_EMAIL_ADDRESS } from "../../data";

const ReachUs: React.FC<{
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
}> = ({ isOpen, setIsOpen }) => {
  return (
    <div className="">
      <Popup
        open={isOpen}
        setOpen={setIsOpen}
        title="We're Just a Call or Email Away"
        // title="Contact Us Anytime: Call or Email"
      >
        <div className="my-4 flex flex-col space-y-4">
          <div className="flex justify-between items-center mr-1 text-secondary">
            <div className="flex gap-x-3 items-center">
              <MdEmail size={22} />
              <p className="">{SUPPORT_EMAIL_ADDRESS}</p>
            </div>
            <GoArrowRight
              size={26}
              className="cursor-pointer"
              onClick={() =>
                window.open(`mailto:${SUPPORT_EMAIL_ADDRESS}`, "_blank")
              }
            />
          </div>
          <div className="flex justify-between items-center mr-1 text-secondary">
            <div className="flex gap-x-3 items-center">
              <MdLocalPhone size={23} />
              <p className="">{CALL_US_CONTACT}</p>
            </div>
            <GoArrowRight
              size={26}
              className="cursor-pointer"
              onClick={() => window.open(`tel:+91${CALL_US_CONTACT}`, "_blank")}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default ReachUs;
