import clsx from "clsx";
import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdMenu } from "react-icons/md";
import MobileSidebar from "../mobile-sidebar";
import { LayoutContext } from "../../context/layout-context";
import Services from "./services";
import PeopleAndCulture from "./people-and-culture";
import ReachUs from "./reach-us";
import Values from "./our-values";

export const DesktopNavbar = () => {
  const [openCareerPopup, setCareerPopup] = useState(false);
  const [openReachUsPopup, setReachUsPopup] = useState(false);
  const { toggleEnquiryDialog } = useContext(LayoutContext);
  const navigate = useNavigate();

  return (
    <nav
      className={clsx(
        "hidden bg-nav w-full md:block sticky drop-shadow-md transition-all duration-300 z-50 top-0 px-9"
      )}
    >
      <div className="flex justify-between items-center px-7 sm:px-0 sm:w-full">
        <img
          src="/logo.svg"
          alt="logo"
          className="h-32 w-auto cursor-pointer"
          onClick={() => navigate("/")}
        />

        <div className="hidden md:flex gap-x-3 items-center sm:gap-x-8">
          <ul className="list-none flex gap-x-3 sm:gap-x-8">
            <NavItem label="Company Overview" src="/company" />
            <Services />
            <li
              className="text-base md:text-[17px] font-medium transition-all duration-200 text-secondary cursor-pointer"
              onClick={() => setCareerPopup(true)}
            >
              People & Culture
            </li>
            <Values />
            <li
              className="text-base md:text-[17px] font-medium transition-all duration-200 text-secondary cursor-pointer"
              onClick={() => setReachUsPopup(true)}
            >
              Reach Us
            </li>
          </ul>
          <button
            className={clsx(
              "bg-gray-800 text-base hover:text-[17px] text-white px-3 py-2 sm:py-3 hover:bg-gray-900 transition-all duration-300 rounded-[3px] drop-shadow-md"
            )}
            type="button"
            onClick={() => toggleEnquiryDialog(true)}
          >
            Enquiry
          </button>
        </div>
      </div>
      <PeopleAndCulture isOpen={openCareerPopup} setIsOpen={setCareerPopup} />
      <ReachUs isOpen={openReachUsPopup} setIsOpen={setReachUsPopup} />
    </nav>
  );
};

const NavItem: React.FC<{ label: string; src: string; className?: any }> = ({
  label,
  src,
  className = "",
}) => {
  return (
    <NavLink to={src}>
      <p
        className={clsx(
          "text-base md:text-[17px] font-medium transition-all duration-200 text-secondary",
          className
        )}
      >
        {label}
      </p>
    </NavLink>
  );
};

export const MobileNavbar = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  return (
    <nav className="md:hidden w-full bg-nav overflow-hidden sticky top-0 z-50 drop-shadow-md">
      <div className="flex justify-between items-center px-5">
        <div className="">
          <img
            src="/logo.svg"
            alt="logo"
            className="h-20 w-auto cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        <MdMenu
          onClick={() => setOpenSidebar(true)}
          className="h-[30px] w-[30px] cursor-pointer text-secondary"
        />
      </div>
      <MobileSidebar open={openSidebar} setOpen={setOpenSidebar} />
    </nav>
  );
};
